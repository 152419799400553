import Api from './Api'
import {handleError} from '../util/ApiErrorUtil'

class Contact {

	static list() {
		return new Promise((resolve, reject)=>{
			return Api.get('/customer').then(companies =>  {
				resolve(companies)
			}).catch((err) => {
				reject(handleError(err))
			})
		})
	}

	static listByCompany(id) {
		return new Promise((resolve, reject)=>{
			return Api.get(`/customer/all/${id}`).then(company =>  {
				resolve(company)
			}).catch((err) => {
				reject(handleError(err))
			})
		})
	}

	static create(company) {
		return new Promise((resolve, reject)=>{
			return Api.post('/customer', company).then(company =>  {
				resolve(company)
			}).catch((err) => {
				reject(handleError(err, "Ocorreu um erro ao criar a empresa"))
			})
		})
	}


}
export default Contact
