import { all, call, fork, put, takeLatest } from "redux-saga/effects";

import {Types, contactList, findByIdSuccess} from "../reducers/Contact"
import {saveStep} from "../reducers/Tutorial"

import ContactService from "../../services/Contact";

import { buttonLoading, toggleModal } from "../actions/loading";
import { errorMessage } from "../actions/FeedbackMessage";




const findByIdRequest = async (id) =>
  await ContactService.listByCompany(id)
    .then((companie) => companie)
    .catch((err) => {throw err})


function* findById({payload}) {

  try {
    const contacts = yield call(findByIdRequest, payload)
    yield put(findByIdSuccess(contacts))
  } catch (error) {
    yield put({ type: Types.CONTACT_FIND_BY_ID_ERROR, payload: error })
  }
}


export function* findByIdSaga() {
  console.log("findByIdSaga")
  yield takeLatest(Types.CONTACT_FIND_BY_ID, findById);
}

export default function* rootSaga() {
  yield all([
    fork(findByIdSaga)
  ]);
}
