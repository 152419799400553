// Action Types
export const Types = {
  CONTACT_CREATE:'CONTACT_CREATE',
  CONTACT_CREATE_SUCESS: 'CONTACT_CREATE_SUCESS',
  CONTACT_CREATE_ERROR: 'CONTACT_CREATE_ERROR',
  CONTACT_UPDATE:'CONTACT_UPDATE',
  CONTACT_UPDATE_SUCESS: 'CONTACT_UPDATE_SUCESS',
  CONTACT_UPDATE_ERROR: 'CONTACT_UPDATE_ERROR',
  CONTACT_LIST:'CONTACT_LIST',
  CONTACT_LIST_ERROR:'CONTACT_LIST_ERROR',
  CONTACT_LIST_SUCESS: 'CONTACT_LIST_SUCESS',
  CONTACT_DELETE:'CONTACT_DELETE',
  CONTACT_DELETE_SUCESS: 'CONTACT_DELETE_SUCESS',
  CONTACT_DELETE_ERROR: 'CONTACT_DELETE_ERROR',
  CONTACT_UPDATE_TUTORIAL_STEP:'CONTACT_UPDATE_TUTORIAL_STEP',
  CONTACT_FIND_BY_ID:'CONTACT_FIND_BY_ID',
  CONTACT_FIND_BY_ID_SUCCESS:'CONTACT_FIND_BY_ID_SUCCESS',
  CONTACT_FIND_BY_ID_ERROR:'CONTACT_FIND_BY_ID_ERROR',
}

// Reducer
const INITIAL_STATE = {
  contact:{},
  contacts:[]
}
 
export default function reducer(state = INITIAL_STATE, action) {

  switch (action.type) {
    
    case Types.CONTACT_LIST_SUCESS: {
      return {
        ...state,
        contact:action.payload
      }
    }
    case Types.CONTACT_FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        contact:action.payload
      }
    }
    default:
      return state;
  }

}

// Action Creators
export const createContact = (contact) => {
  return {
      type: Types.CONTACT_CREATE,
      payload: contact
  };
};

export const updateContact = (contact) => {
  return {
      type: Types.CONTACT_UPDATE,
      payload: contact
  };
};

export const deleteContact = (id) => {
  return {
    type: Types.CONTACT_DELETE,
    payload: id
  };
};

export const contactList = (id) => {
  return {
    type: Types.CONTACT_FIND_BY_ID,
    payload: id
  }
}


export const findByIdSuccess = (contact) => {
  return {
    type: Types.CONTACT_LIST_SUCESS,
    payload: contact
  }
}
