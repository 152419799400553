import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Company from "./Company";
import Bot from "./Bot";
import Loading from "./Loading";
import Channel from "./Channel";
import FeedbackMessage from "./FeedbackMessage"
import Webhook from "./Webhook"
import Conversation from "./Conversation"
import Integration from "./Integration"
import Logs from "./Logs"
import Tutorial from "./Tutorial"
import Filter from "./Filter"
import Chat from "./Chat"
import Feedbacks from "./Feedbacks"
import Files from "./Files"
import User from "./User"
import Role from "./Role"
import Events from "./Events"
import Contact from "./Contact"
import InterventionEvents from "./InterventionEvents";

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  company: Company,
  channel: Channel,
  bot: Bot,
  loading: Loading,
  feedbackMessage: FeedbackMessage,
  webhook: Webhook,
  conversation: Conversation,
  logs: Logs,
  tutorial: Tutorial,
  integration: Integration,
  filter: Filter,
  feedbacks:Feedbacks,
  files: Files,
  user: User,
  chat:Chat,
  role: Role,
  events: Events,
  contact: Contact,
  interventionEvents: InterventionEvents
});

export default reducers;
